<template>
  <div class=" ">
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class="font-bold text-2xl xl:text-3xl my-3">Oops!</div>
      <div class="py-12 px-0">
        <div class=" text-base mb-10">
          您目前的帳號為「一般帳號」，無法使用此功能。<br />
          請升級為<b>買家帳號</b>，享受更完整的服務。
        </div>
        <a
          href="user"
          class="button button--lg text-white bg-theme-1 shadow-md mr-2"
        >
          前往升級
        </a>
      </div>
    </div>

    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  components: {},
  setup() {},
  methods: {}
});
</script>
