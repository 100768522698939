
/* eslint-disable */

import { ref, reactive, onMounted, provide, computed } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  components: {},
  setup() {},
  methods: {}
});
